import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(`../../src/views/homeView/HomeView.vue`),
    meta: { title: "Home" },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import(`../../src/views/policyView/PolicyView.vue`),
    meta: { title: "Policy" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import(`../../src/views/termsView/TermsView.vue`),
    meta: { title: "Terms" },
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: () => import(`../../src/views/notFoundView/NotFoundView.vue`),
    meta: { title: "NotFound" },
  },
];

const router = new VueRouter({
  scrollBehavior() {
    window.document.getElementById("app").scrollTop = 0;
  },
  mode: "history",
  base: "/",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to);

  if (to.path !== "/terms" && to.path !== "/not-found" && to.path !== "/" && to.path !== "/policy") {
    next({ name: "NotFound" });
    return;
  }
  next();
});

export default router;
