<template>
  <v-app>
   <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Regular.ttf");
}
*, html {
    scroll-behavior: smooth !important;
}
.v-application--wrap {
    overflow: hidden;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    label,
    a,
    button {
        font-family: Poppins;
    }
}
</style>
